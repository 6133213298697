import { Card, CardContent, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  textFields: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginTop: '20px',
  },
}));
interface LineInfo {
  name: string;
  description: string;
}
interface Properties {
  lineInfo: LineInfo;
  onChangeLineInfo(lineInfo: LineInfo): void;
}

const SpecifyLineInfo: FC<Properties> = ({ lineInfo, onChangeLineInfo }) => {
  const { classes } = useStyles();
  const [t] = useTranslation();

  const updateLineInfo = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLineInfo = { ...lineInfo };
    newLineInfo[field as keyof LineInfo] = e.target.value;
    onChangeLineInfo(newLineInfo);
  };

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography align="center" variant="subtitle1">
          {t(['line:lineSettings'], { defaultValue: 'Line Settings' })}
        </Typography>
        <TextField
          variant="filled"
          id="select-line-name"
          key="select-line-name"
          value={lineInfo.name}
          className={classes.textFields}
          type={'text'}
          required
          error={lineInfo.name.length === 0}
          fullWidth
          onChange={updateLineInfo('name')}
          label={t(['line:lineName'], { defaultValue: 'Line name' })}
        />
        <TextField
          variant="filled"
          id="select-line-description"
          key="select-line-description"
          value={lineInfo.description}
          className={classes.textFields}
          type={'text'}
          fullWidth
          onChange={updateLineInfo('description')}
          label={t(['line:lineDescription'], { defaultValue: 'Line description' })}
        />
      </CardContent>
    </Card>
  );
};

export default SpecifyLineInfo;

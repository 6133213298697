import { Card, CardContent, CardHeader, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchValue, isSearchValueType } from '@/helpers/input';
import * as Types from '@/types';

interface Properties {
  availableNodes: Types.GraphNode[];
  setupNodes: Types.GraphNode[];
  title: string;
  onAddScrapSensor: (sensorId: string) => void;
  onRemoveScrapSensor: (sensorId: string) => void;
}

const AddScapSensor: FC<Properties> = (props) => {
  const { availableNodes, setupNodes, title, onAddScrapSensor, onRemoveScrapSensor } = props;

  const [searchValues, setSearchValues] = useState<(string | SearchValue)[]>([]);
  const [t] = useTranslation();

  useEffect(() => {
    const newSearchValues = setupNodes.map((node) => {
      return { id: node.peripheralId, label: node.sensorName };
    });
    setSearchValues(newSearchValues);
  }, [setupNodes]);

  const onAutocompleteChange = (value: (string | SearchValue)[]) => {
    const previousSensors = searchValues.filter((s) => isSearchValueType(s)).map((s) => s as SearchValue);
    const newSensors = value.filter((s) => isSearchValueType(s)).map((s) => s as SearchValue);

    if (previousSensors.length < newSensors.length) {
      const addedSensors = newSensors.filter((s) => !previousSensors.some((node) => node.id === s.id));
      addedSensors.forEach((s) => onAddScrapSensor(s.id));
    }
    if (newSensors.length < previousSensors.length) {
      const removedSensors = previousSensors.filter(
        (prevSensor) => !newSensors.some((newSensor) => prevSensor.id === newSensor.id),
      );
      removedSensors.forEach((s) => onRemoveScrapSensor(s.id));
    }
    setSearchValues(value);
  };

  const renderAutocomplete = () => {
    return (
      <Autocomplete
        filterSelectedOptions
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) =>
          isSearchValueType(option) && isSearchValueType(value) ? option.id === value.id : option === value
        }
        value={searchValues}
        onChange={(event, value) => onAutocompleteChange(value)}
        getOptionLabel={(option) => (isSearchValueType(option) ? option.label : option)}
        multiple
        id="tags-standard"
        options={availableNodes.map((node) => {
          return { id: node.peripheralId, label: node.sensorName };
        })}
        renderInput={(params) => (
          <TextField {...params} fullWidth variant="filled" label={t('line:selectSensor', 'Select sensor')} />
        )}
        noOptionsText={t('line:noAvailableSensors', 'No available sensors. Please add counters to the line')}
      />
    );
  };

  return (
    <Card elevation={3}>
      <CardHeader title={title} />
      <CardContent>{renderAutocomplete()}</CardContent>
    </Card>
  );
};

export default AddScapSensor;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Hidden, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import * as Types from '@/types';

import AddScapSensor from './add-scrap-sensor';

const useStyles = makeStyles()(() => ({
  container: {
    alignItems: 'center',
  },
  scrapContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  item: {
    alignSelf: 'center',
    justifySelf: 'center',
    display: 'flex',
  },
  centered: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}));

interface Properties {
  mainSensorNodeName: string;
  mainSensorNodePeripheralId: string;
  availableNodes: Types.GraphNode[];
  nodeLinks: Types.GraphLink[];
  onAddScrapSensor: (sensorId: string, isBeforeMain: boolean) => void;
  onRemoveScrapSensor: (sensorId: string) => void;
}

const LineListTopology: FC<Properties> = (props) => {
  const {
    mainSensorNodePeripheralId,
    mainSensorNodeName,
    availableNodes,
    nodeLinks,
    onAddScrapSensor,
    onRemoveScrapSensor,
  } = props;

  const { classes } = useStyles();
  const [t] = useTranslation();

  const nodesBeforeMain = availableNodes.filter((node) =>
    nodeLinks.some((link) => link.source === node.peripheralId && link.target === mainSensorNodePeripheralId),
  );

  const nodesAfterMain = availableNodes.filter((node) =>
    nodeLinks.some((link) => link.source === mainSensorNodePeripheralId && link.target === node.peripheralId),
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="subtitle1">{t('line:setupScrapSensors', 'Specify scrap sensor placement')}</Typography>
        <Tooltip
          title={t(
            'line:setupScrapSensorsInfo',
            'To correctly calculate the number of items produced, please indicate the placement of all scrap sensors on the line',
          )}
        >
          <span>
            <InfoOutlinedIcon fontSize="small" />
          </span>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container className={classes.container}>
          <Grid className={classes.scrapContainer} item xs={12} md={4}>
            <AddScapSensor
              title={t('line:priorToBottleneck', 'Scrap sensors prior to bottleneck')}
              availableNodes={availableNodes.filter(
                (n) => !nodesAfterMain.some((n2) => n2.peripheralId === n.peripheralId),
              )}
              setupNodes={nodesBeforeMain}
              onAddScrapSensor={(sensorId) => onAddScrapSensor(sensorId, true)}
              onRemoveScrapSensor={(sensorId) => onRemoveScrapSensor(sensorId)}
            />
          </Grid>
          <Grid className={classes.item} item xs={12} md={4}>
            <Hidden mdDown>
              <ArrowForwardIcon className={classes.centered} />
              <Chip
                size="medium"
                label={`${mainSensorNodeName} (${t('line:bottleneckSensor', 'bottleneckSensor')})`}
              />{' '}
              <ArrowForwardIcon className={classes.centered} />
            </Hidden>
          </Grid>
          <Grid className={classes.scrapContainer} item xs={12} md={4}>
            <AddScapSensor
              title={t('line:afterBottleneck', 'Scrap sensors after bottleneck')}
              availableNodes={availableNodes.filter(
                (n) => !nodesBeforeMain.some((n2) => n2.peripheralId === n.peripheralId),
              )}
              setupNodes={nodesAfterMain}
              onAddScrapSensor={(sensorId) => onAddScrapSensor(sensorId, false)}
              onRemoveScrapSensor={(sensorId) => onRemoveScrapSensor(sensorId)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LineListTopology;

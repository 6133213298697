import { Card, CardContent, Chip, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';

import { PartialSensor } from './select-sensor-group';

interface Properties {
  title: string;
  sensors: PartialSensor[];
  selectedSensorIds: string[];
  onSelectSensor(sensor: PartialSensor): void;
  selectDefault?: boolean;
  disabledSensorId: string | undefined;
}
const useStyles = makeStyles()((theme: Theme) => ({
  sensorCard: {
    maxHeight: '180px',
    overflowY: 'scroll',
    margin: '10px',
  },
  sensorChip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  unselected: {
    color: 'black',
  },
  selected: {
    color: 'white',
  },
}));

const SelectSensor: FC<Properties> = (props) => {
  const { title, sensors, selectedSensorIds, onSelectSensor, disabledSensorId } = props;
  const { classes } = useStyles();

  const sortedSensors = sensors.sort((l, r) => l.name.localeCompare(r.name));

  return (
    <Card elevation={3}>
      <CardContent>
        <Typography variant="subtitle1">{title}</Typography>
        <div className={classes.sensorCard}>
          {sortedSensors.map((sensor) => {
            const sensorSelected = selectedSensorIds.some(
              (sensorId) => sensorId === sensor.peripheralId && sensorId !== disabledSensorId,
            );
            return (
              <Tooltip
                placement="top"
                key={`${sensor.name}-${sensor.peripheralId}-tooltip`}
                title={
                  <div>
                    <b>{sensor.name}</b>
                    <br />
                    {sensor.description}
                  </div>
                }
              >
                <Chip
                  key={`${sensor.name}-${sensor.peripheralId}-chip`}
                  label={sensor.name}
                  onClick={() => onSelectSensor(sensor)}
                  className={`${sensorSelected ? classes.selected : classes.unselected} ${classes.sensorChip}`}
                  variant={sensorSelected ? 'filled' : 'outlined'}
                  color={'primary'}
                  disabled={sensor.peripheralId === disabledSensorId}
                />
              </Tooltip>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default SelectSensor;
